$m_color_01 : #876e5c;
$m_color_02 : #ac927f;
$main_color : #f8f8f8;
$shadow_color : #f1f1f1;

$white: #fff;

$mobile : 768px;
$inner : 1280px;

$bp : 130px 0;
$g : 30px;
$br :30px;
$size_18px: 18px;

$fw_thin: 100;
$fw_light: 300;
$fw_medium: 500;
$fw_bold: 700;
$fw_black: 900;