@import './variable';
@import './mixin';

#MainVisual {
    @include section;
    height: 100vh;
    background: $main_color;

    @include flex_custom (flex, auto, row, center, center, auto);

    .inner {
        @include inner;
        @include flex_row;

        .main_visual_slogan {
            flex: 1;

            @include flex_custom (flex, auto, column, baseline, center, auto);

            .main_visual_title_01 {
                margin: 0 0 20px 0;
            }

            .main_visual_title_02 {
                margin: 0 0 50px 0;
            }

            strong {
                font-size: 64px;
                font-weight: $fw_bold;
            }

            span {
                font-size: 64px;
                font-weight: $fw_thin;
            }

            .main_visual_desc {
                max-width: 580px;

                font-size: 22px;
                font-weight: $fw_light;
                line-height: 1.45;
            }
        }

        .main_visual_images {
            flex: 1;
        }
    }

    .scroll {
        @include position(absolute, auto, auto, 150px, 50%, 5);
        transform: translate(-50%, 0);

        .line {
            @include position(absolute, auto, auto, 0, 50%, auto);
            transform: translate(0, 150%);
            width: 1px;
            height: 100px;
            background-color: #b7b7b7;

            span {
                @include position(absolute, 0, 0, auto, auto, auto);
                width: 1px;
                height: 0;
                background-color: #222;
                animation: lineloof 2.5s infinite;
            }
        }
    }

    @keyframes lineloof {
        0% {
            height: 0;
        }

        100% {
            height: 100%;
        }
    }
}

// 플립
@media screen and (min-width: 280px) and (max-width: 360px) {
    #MainVisual {
        height: 100vh;
        background: $main_color;

        @include flex_custom (flex, auto, row, center, center, auto);

        .inner {
            @include inner;
            height: 100vh;
            display: flex;
            flex-direction: column;
            padding: 0 20px;

            .main_visual_slogan {
                flex: 1;

                @include flex_custom (flex, auto, column, center, center, auto);
                padding: 100px 0 0 0;

                .main_visual_title_01 {
                    margin: 0 0 15px 0;
                    text-align: center;
                }

                .main_visual_title_02 {
                    margin: 0 0 30px 0;
                    text-align: center;
                }

                strong {
                    font-size: 30px;
                    font-weight: $fw_bold;
                    line-height: 1.4;
                }

                span {
                    font-size: 30px;
                    font-weight: $fw_thin;
                    line-height: 1.4;
                }

                .main_visual_desc {
                    font-size: 17px;
                    font-weight: $fw_light;
                    line-height: 1.45;

                    padding: 0 0;
                    margin: 0 0 30px 0;
                    text-align: center;
                }
            }

            .main_visual_images {
                flex: 1;
            }
        }

        .scroll {
            display: none;
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #MainVisual {
        height: 100vh;
        background: $main_color;

        @include flex_custom (flex, auto, row, center, center, auto);

        .inner {
            @include inner;
            height: 100vh;
            display: flex;
            flex-direction: column;
            padding: 0 20px;

            .main_visual_slogan {
                flex: 1;

                @include flex_custom (flex, auto, column, center, center, auto);
                padding: 100px 0 0 0;

                .main_visual_title_01 {
                    margin: 0 0 15px 0;
                }

                .main_visual_title_02 {
                    margin: 0 0 30px 0;
                }

                strong {
                    font-size: 32px;
                    font-weight: $fw_bold;
                }

                span {
                    font-size: 32px;
                    font-weight: $fw_thin;
                }

                .main_visual_desc {
                    font-size: 17px;
                    font-weight: $fw_light;
                    line-height: 1.45;

                    padding: 0 30px;
                    text-align: center;
                }
            }

            .main_visual_images {
                flex: 1;
            }
        }

        .scroll {
            display: none;
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #MainVisual {
        height: 100vh;
        background: $main_color;

        @include flex_custom (flex, auto, row, center, center, auto);

        .inner {
            @include inner;
            height: 100vh;
            display: flex;
            flex-direction: row;
            padding: 0 20px;

            .main_visual_slogan {
                flex: 1;

                @include flex_custom (flex, auto, column, center, center, auto);
                padding: 20px 0 0 0;

                .main_visual_title_01 {
                    margin: 0 0 15px 0;
                }

                .main_visual_title_02 {
                    margin: 0 0 30px 0;
                }

                strong {
                    font-size: 32px;
                    font-weight: $fw_bold;
                }

                span {
                    font-size: 32px;
                    font-weight: $fw_thin;
                }

                .main_visual_desc {
                    font-size: 17px;
                    font-weight: $fw_light;
                    line-height: 1.45;

                    padding: 0 30px;
                    text-align: center;
                }
            }

            .main_visual_images {
                flex: 1;
                display: flex;
                align-items: center;
            }
        }

        .scroll {
            @include position(absolute, auto, auto, 150px, 50%, 5);
            transform: translate(-50%, 0);
    
            .line {
                @include position(absolute, auto, auto, 0, 50%, auto);
                transform: translate(0, 150%);
                width: 1px;
                height: 100px;
                background-color: #b7b7b7;
    
                span {
                    @include position(absolute, 0, 0, auto, auto, auto);
                    width: 1px;
                    height: 0;
                    background-color: #222;
                    animation: lineloof 2.5s infinite;
                }
            }
        }
    
        @keyframes lineloof {
            0% {
                height: 0;
            }
    
            100% {
                height: 100%;
            }
        }
    }
}

