@import './variable';
@import './mixin';

#Notice {
    @include section;
    background: url(../../../public/assets/notice_bg.png);
    color: $white;

    .inner {
        @include inner;
        // outline: 1px solid red;

        .section_title {
            @include section_title;
        }

        .section_desc {
            @include section_desc;
            margin: 0 0 70px 0;
            line-height: 1.45;
        }

        .content {
            position: relative;

            .slick-slider {
                // width: 1280px;
                // margin: 0 auto;
                // outline: 1px solid green;

                .slick-list {
                    width: 1165px;
                    margin: 0 auto;
                    // outline: 1px solid red;
                    padding: 20px 0;

                    .slick-slide {
                        &>div {
                            background: #5f4736;
                            border-radius: 30px;
                            width: 343px;
                            margin: 0 22.5px;
                            box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.07);

                            &:hover {
                                outline: 2px solid #efbd8d;
                            }
                            

                            .notice_slide {

                                .notice_itm_text {
                                    padding: 40px 30px;
                                    min-height: 337px;
                                    // outline: 1px solid red;

                                    strong {
                                        display: block;
                                        font-size: 28px;
                                        line-height: 1.4;
                                        min-height: 80px;
                                        margin: 0 0 30px 0;
                                    }

                                    span {
                                        display: block;
                                        font-size: 18px;
                                        font-weight: 100;
                                        line-height: 1.5;
                                        margin: 0 0 50px 0;
                                        max-height: 80px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 3;
                                        -webkit-box-orient: vertical;
                                    }

                                    em {
                                        display: block;
                                        font-size: 18px;
                                        color: #ae9787;
                                        text-align: right;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .arrows {
                color: #d7d7d7;
                font-size: 100px;

                .prev {
                    @include position (absolute, 50%, auto, auto, -70px, auto);
                    transform: translate(0, -40%);
                }

                .next {
                    @include position (absolute, 50%, -60px, auto, auto, auto);
                    transform: translate(0, -40%);
                }

                .btn.active {
                    color: #222;
                }
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #Notice {
        @include section_m;

        .inner {
            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 0 30px 0;
                line-height: 1.45;
                padding: 0 20px;
            }

            .content {

                .slick-slider {
                    width: 100%;
                    // margin: 0 auto;
                    // outline: 1px solid green;
                    padding: 0 20px;

                    .slick-list {
                        width: calc(100% - 40px);
                        margin: 0 auto;
                        // outline: 1px solid red;
                        padding: 20px 0;

                        .slick-slide {
                            &>div {
                                margin: 0 10px;
                                width: auto;

                                .notice_slide {
                                    .notice_itm_text {
                                        padding: 40px 20px;
                                        min-height: 200px;

                                        strong {
                                            font-size: 20px;
                                            min-height: 60px;
                                            margin: 0 0 25px 0;
                                        }

                                        span {
                                            display: block;
                                            font-size: 16px;
                                            font-weight: 100;
                                            line-height: 1.5;
                                            margin: 0 0 35px 0;
                                            max-height: 75px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 3;
                                            -webkit-box-orient: vertical;
                                        }

                                        em {
                                            display: block;
                                            font-size: 15px;
                                            color: #ae9787;
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .arrows {
                    @include position (absolute, 45%, auto, auto, 50%, auto);
                    transform: translate(-50%, -50%);

                    color: #d7d7d7;
                    font-size: 55px;
                    // outline: 1px solid red;
                    width: 100%;

                    .prev {
                        @include position (absolute, 0, auto, auto, 0, auto);
                        transform: translate(0, 0);
                        margin: 0 0 0 -5px;
                    }

                    .next {
                        @include position (absolute, 0, 0, auto, auto, auto);
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #Notice {
        @include section_m;

        .inner {
            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 0 30px 0;
                line-height: 1.45;
                padding: 0 20px;
            }

            .content {

                .slick-slider {
                    width: 100%;
                    // margin: 0 auto;
                    // outline: 1px solid green;
                    padding: 0 20px;

                    .slick-list {
                        width: calc(100% - 40px);
                        margin: 0 auto;
                        // outline: 1px solid red;
                        padding: 20px 0;

                        .slick-slide {
                            &>div {
                                margin: 0 15px;
                                width: auto;
                                margin: 0 15px;

                                .notice_slide {
                                    .notice_itm_text {
                                        padding: 40px 20px;
                                        min-height: 292px;

                                        strong {
                                            font-size: 22px;
                                            min-height: 65px;
                                            margin: 0 0 25px 0;
                                            // outline: 1px solid red;
                                        }

                                        span {
                                            display: block;
                                            font-size: 16px;
                                            font-weight: 100;
                                            line-height: 1.5;
                                            margin: 0 0 35px 0;
                                            max-height: 75px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 3;
                                            -webkit-box-orient: vertical;
                                        }

                                        em {
                                            display: block;
                                            font-size: 15px;
                                            color: #ae9787;
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .arrows {
                    @include position (absolute, 45%, auto, auto, 50%, auto);
                    transform: translate(-50%, -50%);

                    color: #d7d7d7;
                    font-size: 55px;
                    // outline: 1px solid red;
                    width: 100%;

                    .prev {
                        @include position (absolute, 0, auto, auto, 0, auto);
                        transform: translate(0, 0);
                        margin: 0 0 0 -5px;
                    }

                    .next {
                        @include position (absolute, 0, 0, auto, auto, auto);
                        transform: translate(0, 0);
                    }
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #Notice {
        padding: 80px 0;

        .inner {
            @include inner;
            // outline: 1px solid red;

            .section_title {
                @include section_title_t;
            }

            .section_desc {
                @include section_desc_t;
                margin: 0 0 60px 0;
                line-height: 1.45;
            }

            .content {
                position: relative;

                .slick-slider {
                    // width: 1280px;
                    // margin: 0 auto;
                    // outline: 1px solid green;
                    padding: 0 20px;

                    .slick-list {
                        width: calc(100% - 20px);
                        margin: 0 auto;
                        padding: 20px 0;

                        .slick-slide {
                            &>div {
                                border-radius: 30px;
                                width: calc(100% - 20px);
                                margin: 0 auto;
                                box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.07);

                                .notice_slide {
                                    img {
                                        width: 100%;
                                        height: 250px;
                                        object-fit: cover;
                                        border-radius: 30px 30px 0 0;
                                    }

                                    .notice_itm_text {
                                        padding: 40px 30px;
                                        min-height: 338px;
    
                                        strong {
                                            display: block;
                                            font-size: 22px;
                                            line-height: 1.4;
                                            margin: 0 0 30px 0;
                                        }
    
                                        span {
                                            display: block;
                                            font-size: 18px;
                                            font-weight: 100;
                                            line-height: 1.5;
                                            margin: 0 0 50px 0;
                                            max-height: 80px;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            display: -webkit-box;
                                            -webkit-line-clamp: 3;
                                            -webkit-box-orient: vertical;
                                        }
    
                                        em {
                                            display: block;
                                            font-size: 18px;
                                            color: #ae9787;
                                            text-align: right;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .arrows {
                    color: #d7d7d7;
                    font-size: 60px;
                    // outline: 1px solid red;

                    position: relative;

                    .prev {
                        @include position (absolute, 50%, auto, auto, -12px, auto);
                        transform: translate(0, -320%);
                    }

                    .next {
                        @include position (absolute, 50%, -8px, auto, auto, auto);
                        transform: translate(0, -320%);
                    }

                    .btn.active {
                        color: #222;
                    }
                }
            }
        }
    }
}