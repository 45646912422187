@mixin section {
    padding: 130px 20px;
}

@mixin section_m {
    padding: 80px 0;
}

@mixin inner {
    max-width: $inner;
    width: 100%;
    margin: 0 auto;
}

@mixin flex_row {
    display: flex;
    flex-direction: row;
}

@mixin flex_custom (
    $display: flex,
    $flex-wrap: wrap,
    $flex-direction: row,
    $align-items: center,
    $justify-content: space-between,
    $gap: 30px,
) {
    display: $display;
    flex-wrap: $flex-wrap;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    gap: $gap;
}

@mixin flex_column {
    display: flex;
    flex-direction: column;
}

@mixin position(
    $position: relative,
    $top: auto,
    $right: auto,
    $bottom: auto,
    $left: auto,
    $zindex: auto
) {
    position: $position;
    inset: ($top $right $bottom $left);
    z-index: $zindex;
}

@mixin section_title {
    font-size: 70px;
    font-weight: $fw_bold;
    text-align: center;
    margin: 0 0 30px 0;
    text-transform: uppercase;
}

@mixin section_desc {
    font-size: 24px;
    font-weight: $fw_light;
    text-align: center;
}

@mixin section_title_m {
    font-size: 35px;
    font-weight: $fw_bold;
    text-align: center;
    margin: 0 0 30px 0;
    text-transform: uppercase;
}

@mixin section_desc_m {
    font-size: 17px;
    font-weight: $fw_light;
    text-align: center;
}

@mixin section_title_t {
    font-size: 46px;
    font-weight: $fw_bold;
    text-align: center;
    margin: 0 0 30px 0;
    text-transform: uppercase;
}

@mixin section_desc_t {
    font-size: 20px;
    font-weight: $fw_light;
    text-align: center;
}