@import './variable';
@import './mixin';

#ContactUs {
    @include section;

    .inner {
        @include inner;

        .section_title {
            @include section_title;
        }

        .section_desc {
            @include section_desc;
            margin: 0 0 100px 0;
            line-height: 1.45;
        }
    }

    .content {
        width: 1120px;
        margin: 0 auto;
        // outline: 1px solid red;

        @include flex_custom (flex, auto, row, auto, space-between, auto);
        gap: 30px;

        .contact_itm {
            flex: 1;

            form {
                display: flex;
                flex-direction: column;
                gap: 20px;
                font-size: 18px;


                input,
                textarea {
                    padding: 20px;
                    border-radius: 20px;
                    border: 1px solid #d1d1d1;
                    font-family: 'Roboto', 'Noto Sans KR', sans-serif;
                }

                input::placeholder,
                textarea::placeholder {
                    color: #d1d1d1;
                }

                input:focus,
                textarea:focus {
                    outline: 1px solid #b8a89d;
                }

                textarea {
                    resize: none;
                    height: 200px;
                }

                .send {
                    background: $m_color_01;
                    color: $white;
                    border: none;
                    cursor: pointer;
                    height: 65px;

                    &:hover {
                        background: $m_color_02;
                        box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.15);
                        transition: all 0.3s ease 0s;
                    }
                }
            }
        }

        .contact_info {
            flex: 1;

            display: flex;
            align-items: center;

            position: relative;
            padding: 0 0 0 60px;

            .contact_info_text {
                div {
                    margin: 0 0 50px 0;

                    &:last-child {
                        margin: 0;
                    }

                    strong {
                        display: block;
                        font-size: 28px;
                        font-weight: $fw_medium;
                        color: $m_color_01;
                        line-height: 1.20;
                        margin: 0 0 20px 0;
                    }

                    span {
                        display: block;
                        font-size: 20px;
                        font-weight: $fw_light;
                        line-height: 1.20;
                        margin: 0 0 15px 0;
                    }
                }
            }

            .contact_info_img {
                position: absolute;
                top: -80px;
                right: -200px;
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #ContactUs {
        @include section_m;
        padding: 80px 20px;
    
        .inner {
            @include inner;
    
            .section_title {
                @include section_title_m;
            }
    
            .section_desc {
                @include section_desc_m;
                margin: 0 0 40px 0;
                line-height: 1.45;
            }
        }
    
        .content {
            width: 100%;
            margin: 0 auto;
            // outline: 1px solid red;
    
            @include flex_custom (flex, auto, column, auto, space-between, auto);
            gap: 60px;
    
            .contact_itm {
                width: 100%;
    
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    font-size: 16px;
    
                    input,
                    textarea {
                        padding: 20px;
                        border-radius: 20px;
                        border: 1px solid #d1d1d1;
                        font-family: 'Roboto', 'Noto Sans KR', sans-serif;
                    }
    
                    input::placeholder,
                    textarea::placeholder {
                        color: #d1d1d1;
                    }
    
                    input:focus,
                    textarea:focus {
                        outline: 1px solid #b8a89d;
                    }
    
                    textarea {
                        resize: none;
                        height: 200px;
                    }
                }
            }
    
            .contact_info {
                width: 100%;
    
                position: relative;
                padding: 0 0 0 0;
    
                .contact_info_text {
                    div {
                        margin: 0 0 30px 0;
    
                        strong {
                            display: block;
                            font-size: 21px;
                            font-weight: $fw_bold;
                            color: $m_color_01;
                            line-height: 1.20;
                            margin: 0 0 20px 0;
                        }
    
                        span {
                            display: block;
                            font-size: 16px;
                            font-weight: $fw_light;
                            line-height: 1.20;
                            margin: 0 0 10px 0;
                        }
                    }
                }
    
                .contact_info_img {
                    position: absolute;
                    top: 50px;
                    right: -300px;
                    z-index: -1;
                    img {
                        width: 50%;
                    }
                }
            }
        }
    }    
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #ContactUs {
        @include section_m;
        padding: 80px 20px;
    
        .inner {
            @include inner;
    
            .section_title {
                @include section_title_m;
            }
    
            .section_desc {
                @include section_desc_m;
                margin: 0 0 40px 0;
                line-height: 1.45;
            }
        }
    
        .content {
            width: 100%;
            margin: 0 auto;
            // outline: 1px solid red;
    
            @include flex_custom (flex, auto, column, auto, space-between, auto);
            gap: 60px;
    
            .contact_itm {
                width: 100%;
    
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    font-size: 16px;
    
                    input,
                    textarea {
                        padding: 20px;
                        border-radius: 20px;
                        border: 1px solid #d1d1d1;
                        font-family: 'Roboto', 'Noto Sans KR', sans-serif;
                    }
    
                    input::placeholder,
                    textarea::placeholder {
                        color: #d1d1d1;
                    }
    
                    input:focus,
                    textarea:focus {
                        outline: 1px solid #b8a89d;
                    }
    
                    textarea {
                        resize: none;
                        height: 200px;
                    }
                }
            }
    
            .contact_info {
                width: 100%;
    
                position: relative;
                padding: 0 0 0 0;
    
                .contact_info_text {
                    div {
                        margin: 0 0 30px 0;
    
                        strong {
                            display: block;
                            font-size: 21px;
                            font-weight: $fw_bold;
                            color: $m_color_01;
                            line-height: 1.20;
                            margin: 0 0 20px 0;
                        }
    
                        span {
                            display: block;
                            font-size: 16px;
                            font-weight: $fw_light;
                            line-height: 1.20;
                            margin: 0 0 10px 0;
                        }
                    }
                }
    
                .contact_info_img {
                    position: absolute;
                    top: 10px;
                    right: -250px;
                    z-index: -1;
                    img {
                        width: 60%;
                    }
                }
            }
        }
    }    
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #ContactUs {
        padding: 80px 20px;
    
        .inner {
            @include inner;
    
            .section_title {
                @include section_title_t;
            }
    
            .section_desc {
                @include section_desc_t;
                margin: 0 0 60px 0;
                line-height: 1.45;
            }
        }
    
        .content {
            width: 100%;
            margin: 0 auto;
            // outline: 1px solid red;
    
            @include flex_custom (flex, auto, row, auto, space-between, auto);
            gap: 10px;
    
            .contact_itm {
                flex: 1;
    
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    font-size: 18px;
    
    
                    input,
                    textarea {
                        padding: 20px;
                        border-radius: 20px;
                        border: 1px solid #d1d1d1;
                        font-family: 'Roboto', 'Noto Sans KR', sans-serif;
                    }
    
                    input::placeholder,
                    textarea::placeholder {
                        color: #d1d1d1;
                    }
    
                    input:focus,
                    textarea:focus {
                        outline: 1px solid #b8a89d;
                    }
    
                    textarea {
                        resize: none;
                        height: 200px;
                    }
                }
            }
    
            .contact_info {
                flex: 1;
    
                position: relative;
                padding: 0 0 0 40px;
    
                .contact_info_text {
                    div {
                        margin: 20px 0 50px 0;
    
                        strong {
                            display: block;
                            font-size: 26px;
                            font-weight: $fw_medium;
                            color: $m_color_01;
                            line-height: 1.20;
                            margin: 0 0 20px 0;
                        }
    
                        span {
                            display: block;
                            font-size: 18px;
                            font-weight: $fw_light;
                            line-height: 1.20;
                            margin: 0 0 15px 0;
                        }
                    }
                }
    
                .contact_info_img {
                    position: absolute;
                    top: 10px;
                    right: -200px;
                    z-index: -1;
                    img {
                        width: 75%;
                    }
                }
            }
        }
    }
}