@import './variable';
@import './mixin';

.quick_menu {
    position: fixed;
    bottom: 30px;
    right: 50px;

    cursor: pointer;

    .quick_btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;

        width: 90px;
        height: 90px;
        background: #937c6c;
        border-radius: 50%;
        color: $white;
        font-size: 13px;

        &:hover {
            background: #d7a887;
            transition: 0.3s;
        }

        .top_icon {
            width: 22px;
        }

        .contact_icon {
            width: 34px;
        }
    }

    .contact {
        position: absolute;
        bottom: 10px;
        transition: 0.5s;
    }

    .contact.active {
        bottom: 105px;
    }

    .top {
        visibility: hidden;
        opacity: 0;
    }

    .top.active {
        visibility: visible;
        opacity: 1;
        transition: 0.5s;
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    .quick_menu {
        bottom: 20px;
        right: 10px;

        .quick_btn {
    
            width: 60px;
            height: 60px;

            .contact_icon {
                width: 26px;
            }
        }
    
        .contact.active {
            bottom: 70px;
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    .quick_menu {
        bottom: 20px;
        right: 10px;

        .quick_btn {
    
            width: 60px;
            height: 60px;

            .contact_icon {
                width: 26px;
            }
        }
    
        .contact.active {
            bottom: 70px;
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    .quick_menu {
        bottom: 20px;
        right: 10px;

        .quick_btn {
    
            width: 60px;
            height: 60px;

            .contact_icon {
                width: 26px;
            }
        }
    
        .contact.active {
            bottom: 70px;
        }
    }
}