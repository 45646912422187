@import './variable';
@import './mixin';

#About {
    @include section;
    position: relative;

    .inner {
        @include inner;
        // outline: 1px solid red;

        .section_title {
            @include section_title;
        }

        .section_desc {
            @include section_desc;
            margin: 0 0 70px 0;
            line-height: 1.45;
            padding: 0 20px;
        }

        .about_dog03 {
            position: absolute;
            top: -60px;
            z-index: 1;
        }

        .about_dog01 {
            position: absolute;
            top: -60px;
            z-index: -1;
        }

        .about_dog02 {
            position: absolute;
            top: 210px;
            z-index: 1;
        }

        .content {
            display: flex;
            justify-content: space-between;

            img {
                min-width: 400px;
                border-radius: 30px 30px 0 0;
            }

            .about_content_text {
                width: 400px;
                min-height: 300px;
                background: #f8f8f8;
                padding: 50px 35px;
                border-radius: 0 0 30px 30px;

                strong {
                    display: block;
                    font-size: 28px;
                    font-weight: $fw_medium;
                    margin: 0 0 20px 0;
                    line-height: 1.45;
                }

                span {
                    display: block;
                    font-size: 18px;
                    font-weight: $fw_light;
                    line-height: 1.45;
                }
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #About {
        padding: 80px 0 130px 0;
        .inner {
            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 0 35px 0;
                line-height: 1.45;
            }

            .about_dog03 {
                width: 130px;
                z-index: 1;
            }

            .about_dog01 {
                width: 130px;
                z-index: 1;
            }

            .slick-slider {
                width: 100%;
                // background: red;

                .slick-list {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    // background: yellowgreen;

                    .slick-slide {
                        &>div {
                            margin: 0 15px;

                            .about_content {
                                border-radius: 30px;

                                img {
                                    width: 100%;
                                    border-radius: 30px 30px 0 0;
                                }

                                .about_content_text {
                                    width: 100%;
                                    min-height: 320px;
                                    background: #f8f8f8;
                                    padding: 20px;
                                    border-radius: 0 0 30px 30px;

                                    strong {
                                        display: block;
                                        font-size: 22px;
                                        font-weight: $fw_medium;
                                        margin: 0 0 20px 0;
                                        line-height: 1.45;
                                    }

                                    span {
                                        display: block;
                                        font-size: 16px;
                                        font-weight: $fw_light;
                                        line-height: 1.45;
                                    }
                                }
                            }
                        }
                    }
                }

                .slick-dots {
                    position: absolute;
                    bottom: -60px;

                    li {
                        margin: 0 15px;

                        button {


                            &:before {
                                width: 40px;
                                height: 5px;
                                border-radius: 10px;
                                background: #d3d3d3;
                                font-size: 0;
                            }
                        }


                        &.slick-active button:before {
                            width: 40px;
                            height: 5px;
                            border-radius: 10px;
                            background: $m_color_01;
                        }
                    }
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #About {
        padding: 80px 0 130px 0;
        .inner {
            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 0 50px 0;
                line-height: 1.45;
            }

            .about_dog03 {
                width: 150px;
            }

            .about_dog01 {
                width: 150px;
            }

            .slick-slider {
                width: 100%;
                // background: red;

                .slick-list {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    // background: yellowgreen;

                    .slick-slide {
                        &>div {
                            margin: 0 15px;

                            .about_content {
                                border-radius: 30px;

                                img {
                                    width: 100%;
                                    border-radius: 30px 30px 0 0;
                                }

                                .about_content_text {
                                    width: 100%;
                                    min-height: 300px;
                                    background: #f8f8f8;
                                    padding: 40px;
                                    border-radius: 0 0 30px 30px;

                                    strong {
                                        display: block;
                                        font-size: 22px;
                                        font-weight: $fw_medium;
                                        margin: 0 0 20px 0;
                                        line-height: 1.45;
                                    }

                                    span {
                                        display: block;
                                        font-size: 16px;
                                        font-weight: $fw_light;
                                        line-height: 1.45;
                                    }
                                }
                            }
                        }
                    }
                }

                .slick-dots {
                    position: absolute;
                    bottom: -60px;

                    li {
                        margin: 0 15px;

                        button {


                            &:before {
                                width: 40px;
                                height: 5px;
                                border-radius: 10px;
                                background: #d3d3d3;
                                font-size: 0;
                            }
                        }


                        &.slick-active button:before {
                            width: 40px;
                            height: 5px;
                            border-radius: 10px;
                            background: $m_color_01;
                        }
                    }
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #About {
        @include section_m;
        .inner {
            padding: 0 10px;
            .section_title {
                @include section_title_t;
            }

            .section_desc {
                @include section_desc_t;
                margin: 0 0 50px 0;
                line-height: 1.45;
            }

            .about_dog03 {
                position: absolute;
                top: -60px;
                z-index: 1;
                width: 250px;
            }

            .about_dog01 {
                position: absolute;
                top: -60px;
                z-index: -1;
                width: 250px;
            }

            .about_dog02 {
                position: absolute;
                top: 150px;
                z-index: 1;
                width: 80px;
            }

            .content {
                display: flex;
                justify-content: space-between;

                img {
                    min-width: 100%;
                    border-radius: 30px 30px 0 0;
                }

                .about_content {
                    width: 100%;
                    padding: 0 10px;

                    .about_content_text {

                        width: 100%;
                        min-height: 380px;
                        padding: 30px;
                        border-radius: 0 0 30px 30px;

                        strong {
                            font-size: 24px;
                        }

                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}