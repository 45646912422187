@import './variable';
@import './mixin';

#Service {
    @include section;
    background: $m_color_01;
    color: $white;

    .inner {
        @include inner;
        position: relative;
        // outline: 1px solid red;

        .section_title {
            @include section_title;
        }

        .section_desc {
            @include section_desc;
            margin: 0 0 120px 0;
            line-height: 1.45;
        }

        .strip {
            position: absolute;
            top: -230px;
            right: -22%;
        }

        .content {
            display: flex;

            .service_phone {
                margin: 0 60px 0 0;
            }

            .service_content_text {
                display: flex;
                flex-direction: column;

                .service_content_title {
                    margin: 0 0 50px 0;

                    .service_content_title_01 {
                        font-size: 64px;
                        font-weight: $fw_thin;
                        line-height: 1.45;
                    }

                    .service_content_title_02 {
                        font-size: 64px;
                        font-weight: $fw_bold;
                        color: #ffefc3;
                    }
                }

                .service_app {
                    display: flex;
                    gap: 20px;
                    margin: 0 0 80px 0;

                    button {
                        width: 350px;
                        padding: 20px;
                        background: $white;
                        font-size: 24px;
                        color: #222;
                        border-radius: 50px;

                        svg {
                            width: 30px;
                            height: 30px;
                        }

                        &:hover {
                            background: #fbf7ec;
                            box-shadow: 0px 0px 20px 0px rgb(251, 247, 236, 0.5);
                            transition: 0.3s;
                            font-weight: $fw_bold;
                            color: #5f4736;
                        }
                    }

                    .android {
                        svg {
                            width: 50px;
                            vertical-align: middle;
                            margin: 0 10px 0 0;
                        }
                    }

                    .ios {
                        svg {
                            vertical-align: -5px;
                            margin: 0 10px 0 0;
                        }
                    }
                }

                .service_content_desc {

                    li {
                        display: flex;
                        align-items: center;
                        gap: 30px;
                        font-size: 24px;
                        font-weight: $fw_light;
                        line-height: 1.45;
                        margin: 0 0 40px 0;
                    }

                    .service_content_desc_01 {
                        max-width: 500px;
                    }
                }
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #Service {
        @include section_m;

        .inner {

            .service_bg_m {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 850px;
                    background: url(../../../public/assets/service_bg_m.png)no-repeat center center/contain;
                }
            }

            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 0 50px 0;
                line-height: 1.45;
                padding: 0 20px;
            }

            .strip {
                position: absolute;
                top: -150px;
                right: -35%;
                width: 70%;
            }

            .content {
                display: flex;

                .service_phone {
                    width: 80%;
                    margin: 0 0 30px 0;
                }

                .service_content_text {
                    padding: 0 20px;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;


                    .service_content_title {
                         // outline: 1px solid red;
                         margin: 0 0 50px 0;
                         padding: 0 20px;
                         text-align: center;
                         position: relative;
 
                         &::before {
                             content: "";
                             position: absolute;
                             top: 0px;
                             left: -5px;
                             width: 38px;
                             height: 6px;
                             background: #feae69;
                             rotate: -45deg;
                         }
 
                         &::after {
                             content: "";
                             position: absolute;
                             bottom: 0;
                             right: -5px;
                             width: 38px;
                             height: 6px;
                             background: #feae69;
                             rotate: 135deg;
                         }

                        .service_content_title_01 {
                            font-size: 24px;
                            font-weight: $fw_bold;
                            line-height: 1.45;
                        }

                        .service_content_title_02 {
                            font-size: 24px;
                            font-weight: $fw_bold;
                            color: #ffefc3;
                        }
                    }

                    .service_app {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        margin: 0 0 40px 0;
                        padding: 20px;

                        button {
                            width: 100%;
                            padding: 15px 30px 17px 30px;
                            background: $white;
                            font-size: 18px;
                            font-weight: $fw_medium;
                            color: #222;
                            border-radius: 30px;

                            svg {
                                width: 25px;
                                height: 25px;
                            }
                        }

                        .android {
                            svg {
                                width: auto;
                                vertical-align: -5px;
                                margin: 0 10px 0 0;
                            }
                        }

                        .ios {
                            svg {
                                vertical-align: -5px;
                                margin: 0 10px 0 0;
                            }
                        }

                    }

                    .service_content_desc {
                        padding: 0 0;

                        li {
                            img {
                                width: 60px;
                            }

                            display: flex;
                            align-items: center;
                            gap: 10px;
                            font-size: 16px;
                            font-weight: $fw_light;
                            line-height: 1.45;
                            margin: 0 0 30px 0;

                            &:last-child {
                                margin: 0;
                            }
                        }

                        .service_content_desc_01 {
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #Service {
        @include section_m;

        .inner {

            .service_bg_m {
                position: relative;

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1100px;
                    background: url(../../../public/assets/service_bg_m.png)no-repeat center center/contain;
                }
            }

            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 0 50px 0;
                line-height: 1.45;
                padding: 0 20px;
            }

            .strip {
                position: absolute;
                top: -150px;
                right: -35%;
                width: 70%;
            }

            .content {
                display: flex;

                .service_phone {
                    width: 80%;
                    margin: 0 0 50px 0;
                }

                .service_content_text {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;


                    .service_content_title {
                        // outline: 1px solid red;
                        margin: 0 0 50px 0;
                        padding: 0 20px;
                        text-align: center;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0px;
                            left: -5px;
                            width: 38px;
                            height: 6px;
                            background: #feae69;
                            rotate: -45deg;
                        }

                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            right: -5px;
                            width: 38px;
                            height: 6px;
                            background: #feae69;
                            rotate: 135deg;
                        }

                        .service_content_title_01 {
                            font-size: 30px;
                            font-weight: $fw_bold;
                            line-height: 1.45;
                        }

                        .service_content_title_02 {
                            font-size: 30px;
                            font-weight: $fw_bold;
                            color: #ffefc3;
                        }
                    }

                    .service_app {
                        display: flex;
                        flex-direction: column;
                        gap: 20px;
                        margin: 0 0 60px 0;
                        padding: 20px;

                        button {
                            width: 100%;
                            padding: 16px 60px;
                            background: $white;
                            font-size: 22px;
                            font-weight: $fw_medium;
                            color: #222;
                            border-radius: 50px;
                        }

                    }

                    .service_content_desc {
                        padding: 0 20px;

                        li {
                            display: flex;
                            align-items: center;
                            gap: 20px;
                            font-size: 16px;
                            font-weight: $fw_light;
                            line-height: 1.45;
                            margin: 0 0 20px 0;

                            &:last-child {
                                margin: 0;
                            }
                        }

                        .service_content_desc_01 {
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #Service {
        @include section_m;
        padding: 80px 20px;
        background: $m_color_01;
        color: $white;

        .inner {
            @include inner;
            position: relative;
            // outline: 1px solid red;

            .section_title {
                @include section_title_t;
            }

            .section_desc {
                @include section_desc_t;
                margin: 0 0 60px 0;
                line-height: 1.45;
            }

            .strip {
                position: absolute;
                top: -150px;
                right: -25%;
                width: 50%;
            }

            .content {
                width: 100%;
                display: flex;

                .service_phone {
                    width: 45%;
                    margin: 0 0 0 0;
                }

                img {
                    margin: 0 0 0 0;
                    width: 50%;
                    height: 50%;
                }

                .service_content_text {
                    display: flex;
                    flex-direction: column;
                    padding: 0 50px 0 0;

                    .service_content_title {
                        margin: 0 0 20px 0;

                        .service_content_title_01 {
                            font-size: 34px;
                            font-weight: $fw_thin;
                            line-height: 1.45;
                        }

                        .service_content_title_02 {
                            font-size: 34px;
                            font-weight: $fw_bold;
                            color: #ffefc3;
                        }
                    }

                    .service_app {
                        display: flex;
                        gap: 10px;
                        margin: 0 0 40px 0;

                        button {
                            width: 180px;
                            padding: 15px 20px;
                            background: $white;
                            font-size: 16px;
                            color: #222;
                            border-radius: 50px;

                            svg {
                                width: 20px;
                                height: 20px;
                            }

                            &:hover {
                                background: #fbf7ec;
                                box-shadow: 0px 0px 20px 0px rgb(251, 247, 236, 0.5);
                                transition: 0.3s;
                                font-weight: $fw_bold;
                                color: #5f4736;
                            }
                        }

                        .android {
                            svg {
                                width: 50px;
                                vertical-align: middle;
                                margin: 0 0 0 -10px;
                            }
                        }

                        .ios {
                            svg {
                                vertical-align: -5px;
                                margin: 0 10px 0 0;
                            }
                        }
                    }

                    .service_content_desc {
                        li {
                            display: flex;
                            align-items: center;
                            gap: 30px;
                            font-size: 18px;
                            font-weight: $fw_light;
                            line-height: 1.45;
                            margin: 0 0 30px 0;

                            img {
                                width: 60px;
                            }

                            &:last-child {
                                margin: 0;
                            }
                        }

                        .service_content_desc_01 {
                            max-width: 500px;
                        }
                    }
                }
            }
        }
    }
}