@import './variable';
@import './mixin';

#Partners {
   padding: 130px 0;

    .inner {
        @include inner;

        .section_title {
            @include section_title;
        }

        .section_desc {
            @include section_desc;
            margin: 0 0 85px 0;
            line-height: 1.45;
        }
    }

    .content {
        position: relative;
        width: 1720px;
        height: 300px;
        margin: 0 auto;
        overflow: hidden;

        .partners_itm {
            &:hover {
                animation-play-state: paused;
            }

            ul {
                display: flex;
                gap: 15px;
                margin: 0 15px 0 0;

                li {
                    border: 1px solid #d1d1d1;
                    border-radius: 15px;

                    &:hover {
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
                        border: $white;
                    }

                    a {
                        img {
                            border-radius: 15px;
                        }
                    }
                }
            }
        }

        .partners_itm_top {
            position: absolute;
            top: 15px;
            animation: move01 20s linear infinite;
            width: 200%;

            display: flex;

            @keyframes move01 {
                0% {
                    left: 0;
                }

                100% {
                    left: -100%;
                }
            }
        }

        .partners_itm_bottom {
            position: absolute;
            top: 165px;
            animation: move02 20s linear infinite;
            width: 200%;
            
            display: flex;

            @keyframes move02 {
                0% {
                    left: -100%;
                }

                100% {
                    left: 0;
                }
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #Partners {
        @include section_m;
    
        .inner {
    
            .section_title {
                @include section_title_m;
            }
    
            .section_desc {
                @include section_desc_m;
                margin: 0 0 30px 0;
                line-height: 1.45;
                padding: 0 20px;
            }
        }
    
        .content {
            position: relative;
            width: 100%;
            height: 100%;

            .partners_itm {
    
                ul {
                    display: flex;
                    gap: 15px;
                    margin: 10px 0;
                    padding: 0 20px;

                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 20;
    
                    li {
                        border: 1px solid #d1d1d1;
                        border-radius: 10px;
    
                        a {
                            img {
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
    
            .partners_itm_top {
                position: static;
            }
    
            .partners_itm_bottom {
                position: static;
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) { 
    #Partners {
        @include section_m;
    
        .inner {
    
            .section_title {
                @include section_title_m;
            }
    
            .section_desc {
                @include section_desc_m;
                margin: 0 0 30px 0;
                line-height: 1.45;
                padding: 0 20px;
            }
        }
    
        .content {
            position: relative;
            width: 100%;
            height: 100%;

            .partners_itm {
    
                ul {
                    display: flex;
                    gap: 15px;
                    margin: 10px 0;
                    padding: 0 20px;

                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-gap: 20;
    
                    li {
                        border: 1px solid #d1d1d1;
                        border-radius: 10px;
    
                        a {
                            img {
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }
    
            .partners_itm_top {
                position: static;
            }
    
            .partners_itm_bottom {
                position: static;
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #Partners {
        @include section_m;
    
        .inner {
            @include inner;
    
            .section_title {
                @include section_title_t;
            }
    
            .section_desc {
                @include section_desc_t;
                margin: 0 0 60px 0;
                line-height: 1.45;
            }
        }
    
        .content {
            position: relative;
            width: 1720px;
            height: 300px;
            margin: 0 auto;
            overflow: hidden;
    
            .partners_itm {
                &:hover {
                    animation-play-state: paused;
                }
    
                ul {
                    display: flex;
                    gap: 15px;
                    margin: 0 15px 0 0;
    
                    li {
                        border: 1px solid #d1d1d1;
                        border-radius: 15px;
    
                        &:hover {
                            box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.07);
                            border: $white;
                        }
    
                        a {
                            img {
                                border-radius: 15px;
                            }
                        }
                    }
                }
            }
    
            .partners_itm_top {
                position: absolute;
                top: 15px;
                animation: move01 20s linear infinite;
                width: 200%;
    
                display: flex;
    
                @keyframes move01 {
                    0% {
                        left: 0;
                    }
    
                    100% {
                        left: -100%;
                    }
                }
            }
    
            .partners_itm_bottom {
                position: absolute;
                top: 165px;
                animation: move02 20s linear infinite;
                width: 200%;
                
                display: flex;
    
                @keyframes move02 {
                    0% {
                        left: -100%;
                    }
    
                    100% {
                        left: 0;
                    }
                }
            }
        }
    }
}