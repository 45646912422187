@import './variable';
@import './mixin';

// Privacy 창이 열릴때 뒷부분 스크롤 막기
body.locked {
    overflow: hidden;
}

.privacy_wrapper {
    @include position (fixed, 0, 0, auto, 0, 999);
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);


    .privacy {
        @include position (absolute, 50%, auto, auto, 50%, auto);
        transform: translate(-50%, -50%);
        width: 1280px;

        max-height: calc(100vh - 200px);
        overflow-y: auto;

        background: $white;
        z-index: 999;

        @include flex_custom (flex, auto, column, auto, center, auto);


        box-shadow: 0px 0px 50px rgba(61, 61, 61, 0.3);
        padding: 100px;

        .close {
            @include position (sticky, 0, auto, auto, auto, auto);
            transform: translate(100%, -80%);

            svg {
                width: 45px;
                height: 45px;
                color: #bbb;

                cursor: pointer;
            }
        }


        .privacy_text {
            padding: 100px 0;
            max-height: calc(100vh - 200px);

            h2 {
                font-size: 50px;
                font-weight: $fw_thin;
                margin: 0 0 60px 0;
                text-align: left;
            }

            p {
                font-size: 16px;
                font-weight: $fw_light;
                line-height: 1.45;
                margin: 0 0 30px 0;
            }

            strong {
                font-size: 20px;
                font-weight: $fw_medium;
                display: block;
                margin: 0 0 15px 0;
            }

            .last {
                height: 70px;
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {

    // Privacy 창이 열릴때 뒷부분 스크롤 막기
    body.locked {
        overflow: hidden;
    }

    .privacy_wrapper {
        @include position (fixed, 0, 0, auto, 0, 99999);
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);


        .privacy {
            overflow-x: hidden;
            @include position (absolute, 50%, auto, auto, 50%, auto);
            transform: translate(-50%, -50%);
            width: calc(100% - 40px);

            max-height: calc(100vh - 200px);
            overflow-y: auto;

            background: $white;
            z-index: 999;

            @include flex_custom (flex, auto, column, auto, center, auto);


            box-shadow: 0px 0px 50px rgba(61, 61, 61, 0.3);
            padding: 30px;

            .close {
                @include position (sticky, 0, auto, auto, auto, auto);
                transform: translate(100%, -80%);

                svg {
                    width: 26px;
                    height: 26px;
                    color: #bbb;

                    cursor: pointer;
                }
            }

            .privacy_text {
                padding: 50px 0;
                max-height: calc(100vh - 200px);

                h2 {
                    font-size: 22px;
                    font-weight: $fw_thin;
                    margin: 0 0 50px 0;
                    text-align: left;

                    text-align: center;
                }

                p {
                    font-size: 15px;
                    font-weight: $fw_light;
                    line-height: 1.45;
                    margin: 0 0 30px 0;
                }

                strong {
                    font-size: 18px;
                    line-height: 1.45;
                    font-weight: $fw_medium;
                    display: block;
                    margin: 0 0 15px 0;
                }

                .last {
                    height: 70px;
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 768px) {

    // Privacy 창이 열릴때 뒷부분 스크롤 막기
    body.locked {
        overflow: hidden;
    }

    .privacy_wrapper {
        @include position (fixed, 0, 0, auto, 0, 99999);
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);


        .privacy {
            overflow-x: hidden;
            @include position (absolute, 50%, auto, auto, 50%, auto);
            transform: translate(-50%, -50%);
            width: calc(100% - 40px);

            max-height: calc(100vh - 200px);
            overflow-y: auto;

            background: $white;
            z-index: 999;

            @include flex_custom (flex, auto, column, auto, center, auto);


            box-shadow: 0px 0px 50px rgba(61, 61, 61, 0.3);
            padding: 50px;

            .close {
                @include position (sticky, 0, auto, auto, auto, auto);
                transform: translate(102%, -100%);

                svg {
                    width: 32px;
                    height: 32px;
                    color: #bbb;

                    cursor: pointer;
                }
            }

            .privacy_text {
                padding: 50px 0;
                max-height: calc(100vh - 200px);

                h2 {
                    font-size: 28px;
                    font-weight: $fw_thin;
                    margin: 0 0 50px 0;
                    text-align: left;

                    text-align: center;
                }

                p {
                    font-size: 15px;
                    font-weight: $fw_light;
                    line-height: 1.45;
                    margin: 0 0 30px 0;
                }

                strong {
                    font-size: 20px;
                    font-weight: $fw_medium;
                    display: block;
                    margin: 0 0 15px 0;
                }

                .last {
                    height: 70px;
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {

    // Privacy 창이 열릴때 뒷부분 스크롤 막기
    body.locked {
        overflow: hidden;
    }

    .privacy_wrapper {
        @include position (fixed, 0, 0, auto, 0, 99999);
        height: 100vh;
        background: rgba(0, 0, 0, 0.6);


        .privacy {
            overflow-x: hidden;
            @include position (absolute, 50%, auto, auto, 50%, auto);
            transform: translate(-50%, -50%);
            width: calc(100% - 40px);

            max-height: calc(100vh - 200px);
            overflow-y: auto;

            background: $white;
            z-index: 999;

            @include flex_custom (flex, auto, column, auto, center, auto);


            box-shadow: 0px 0px 50px rgba(61, 61, 61, 0.3);
            padding: 50px;

            .close {
                @include position (sticky, 0, auto, auto, auto, auto);
                transform: translate(102%, -100%);

                svg {
                    width: 32px;
                    height: 32px;
                    color: #bbb;

                    cursor: pointer;
                }
            }

            .privacy_text {
                padding: 50px 0;
                max-height: calc(100vh - 200px);

                h2 {
                    font-size: 28px;
                    font-weight: $fw_thin;
                    margin: 0 0 50px 0;
                    text-align: left;

                    text-align: center;
                }

                p {
                    font-size: 15px;
                    font-weight: $fw_light;
                    line-height: 1.45;
                    margin: 0 0 30px 0;
                }

                strong {
                    font-size: 20px;
                    font-weight: $fw_medium;
                    display: block;
                    margin: 0 0 15px 0;
                }

                .last {
                    height: 70px;
                }
            }
        }
    }
}