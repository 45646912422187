@import './variable';
@import './mixin';

.header {
    @include position(fixed, 0, 0, auto, 0, auto);
    background: $white;
    height: 80px;
    display: flex;
    align-items: center;
    padding: 0 20px;

    &.active {
        @include position(fixed, 0, 0, auto, 0, 10);
        box-shadow: rgba(216, 216, 216, 0.3) 3px 4px 5px 0;
        transition: all 0.3s ease 0s;
    }

    .header_wrap {
        width: 1720px;
        margin: 0 auto;
        @include flex_custom (flex, auto, row, center, space-between, auto);

        .gnb {
            ul {
                display: flex;
                gap: 40px;
                font-size: $size_18px;

                li {
                    line-height: 80px;
                    padding: 0 5px;
                    cursor: pointer;

                    &:hover {
                        font-weight: $fw_bold;
                        color: $m_color_02;
                    }

                    &.active {
                        font-weight: $fw_bold;
                        color: $m_color_02;
                        border-bottom: 4px solid $m_color_02;
                        transition: all 0s ease 0.2s;
                    }
                }
            }
        }

        .language {
            button {
                padding: 8px 20px;
                background: $m_color_01;
                border-radius: 20px;
                color: $white;
                font-size: 15px;

                &:hover {
                    background: $m_color_02;
                    box-shadow: 5px 5px 8px 0px rgba(0, 0, 0, 0.15);
                    transition: all 0.3s ease 0s;
                }
            }
        }
    }
}

// 플립
@media screen and (min-width: 280px) and (max-width: 360px) {
    .header {
        @include position (fixed, 0, 0, auto, 0, 9999);
        height: 65px;

        &.active {
            @include position(fixed, 0, 0, auto, 0, 9999);
        }

        .header_wrap {
            position: relative;
            width: 100%;
            margin: 0 auto;
            @include flex_custom (flex, auto, row, center, start, auto);

            height: 65px;
            padding: 0 0;

            .gnb {
                display: none;
            }

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-32%, -50%);

                img {
                    width: 80%;
                }
            }

            .mopen {
                display: block;
                position: fixed;
                top: 20px;
                right: 20px;

                z-index: 999999;

                width: 40px;
                height: 30px;
                cursor: pointer;
                // background: red;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    inset: 5px 5px auto 5px;
                    background: #222;
                    height: 4px;
                }

                &::before {
                    inset: 5px 5px auto 5px;
                }

                &::after {
                    inset: auto 5px 5px 5px;
                }

                &.active::before,
                &.active::after {
                    opacity: 0;
                }

                &.active button::before {
                    transition: 0.3s;
                    inset: 50% 5px auto 5px;
                    transform: translate(0, -50%) rotate(45deg);
                    background: #bcbcbc;
                }

                &.active button::after {
                    transition: 0.3s 0.3s;
                    inset: 50% 5px auto 5px;
                    transform: translate(0, -50%) rotate(-45deg);
                    background: #bcbcbc;
                }

                button {
                    font-size: 0;
                    border: none;
                    outline: none;
                    background: transparent;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        inset: 44% 5px auto 5px;
                        transform: translate(0, 0);
                        background: #222;
                        height: 4px;
                        transition: 0.3s;
                    }
                }

                .mopen_bg {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 100vh;
                    z-index: -999;
                    background: rgb(0, 0, 0, 0.5);
                }

                .aisde_menu {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 20%;
                    right: 0;
                    z-index: -1;
                    background: $white;
                    padding: 40px;
                    border-radius: 0 0 30px 30px;
                    text-align: center;

                    box-shadow:
                    0px 10px 20px rgba(0, 0, 0, 0.2);

                    ul {
                        font-size: 20px;
                        font-weight: $fw_medium;
                        display: flex;
                        flex-direction: column;
                        gap: 60px;
                        padding: 10px;

                        li:hover {
                            color: $m_color_01;
                            text-decoration: underline;
                            text-underline-offset: 8px;
                        }
                    }
                }
            }

            .language {
                button {
                    font-size: 12px;
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 768px) {
    .header {
        @include position (fixed, 0, 0, auto, 0, 9999);
        height: 65px;

        &.active {
            @include position(fixed, 0, 0, auto, 0, 9999);
        }

        .header_wrap {
            position: relative;
            width: 100%;
            margin: 0 auto;
            @include flex_custom (flex, auto, row, center, start, auto);

            height: 65px;
            padding: 0 20px;

            .gnb {
                display: none;
            }

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-32%, -50%);

                img {
                    width: 80%;
                }
            }

            .mopen {
                display: block;
                position: fixed;
                top: 20px;
                right: 20px;

                z-index: 999999;

                width: 40px;
                height: 30px;
                cursor: pointer;
                // background: red;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    inset: 5px 5px auto 5px;
                    background: #222;
                    height: 4px;
                }

                &::before {
                    inset: 5px 5px auto 5px;
                }

                &::after {
                    inset: auto 5px 5px 5px;
                }

                &.active::before,
                &.active::after {
                    opacity: 0;
                }

                &.active button::before {
                    transition: 0.3s;
                    inset: 50% 5px auto 5px;
                    transform: translate(0, -50%) rotate(45deg);
                    background: #bcbcbc;
                }

                &.active button::after {
                    transition: 0.3s 0.3s;
                    inset: 50% 5px auto 5px;
                    transform: translate(0, -50%) rotate(-45deg);
                    background: #bcbcbc;
                }

                button {
                    font-size: 0;
                    border: none;
                    outline: none;
                    background: transparent;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        inset: 44% 5px auto 5px;
                        transform: translate(0, 0);
                        background: #222;
                        height: 4px;
                        transition: 0.3s;
                    }
                }

                .mopen_bg {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 100vh;
                    z-index: -999;
                    background: rgb(0, 0, 0, 0.5);
                }

                .aisde_menu {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 20%;
                    right: 0;
                    z-index: -1;
                    background: $white;
                    padding: 50px;
                    border-radius: 0 0 30px 30px;
                    text-align: center;

                    box-shadow:
                    0px 10px 20px rgba(0, 0, 0, 0.2);

                    ul {
                        font-size: 24px;
                        font-weight: $fw_medium;
                        display: flex;
                        flex-direction: column;
                        gap: 60px;
                        padding: 20px;

                        li:hover {
                            color: $m_color_01;
                            text-decoration: underline;
                            text-underline-offset: 8px;
                        }
                    }
                }
            }

            .language {
                button {
                    font-size: 12px;
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    .header {
        @include position (fixed, 0, 0, auto, 0, 9999);
        height: 65px;

        &.active {
            @include position(fixed, 0, 0, auto, 0, 9999);
        }

        .header_wrap {
            position: relative;
            width: 100%;
            margin: 0 auto;
            @include flex_custom (flex, auto, row, center, start, auto);

            height: 65px;
            padding: 0 20px;

            .gnb {
                display: none;
            }

            .logo {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-32%, -50%);

                img {
                    width: 80%;
                }
            }

            .mopen {
                display: block;
                position: fixed;
                top: 20px;
                right: 20px;

                z-index: 999999;

                width: 40px;
                height: 30px;
                cursor: pointer;
                // background: red;

                &::before,
                &::after {
                    content: "";
                    position: absolute;
                    inset: 5px 5px auto 5px;
                    background: #222;
                    height: 4px;
                }

                &::before {
                    inset: 5px 5px auto 5px;
                }

                &::after {
                    inset: auto 5px 5px 5px;
                }

                &.active::before,
                &.active::after {
                    opacity: 0;
                }

                &.active button::before {
                    transition: 0.3s;
                    inset: 50% 5px auto 5px;
                    transform: translate(0, -50%) rotate(45deg);
                    background: #bcbcbc;
                }

                &.active button::after {
                    transition: 0.3s 0.3s;
                    inset: 50% 5px auto 5px;
                    transform: translate(0, -50%) rotate(-45deg);
                    background: #bcbcbc;
                }

                button {
                    font-size: 0;
                    border: none;
                    outline: none;
                    background: transparent;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        inset: 44% 5px auto 5px;
                        transform: translate(0, 0);
                        background: #222;
                        height: 4px;
                        transition: 0.3s;
                    }
                }

                .mopen_bg {
                    position: fixed;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 100vh;
                    z-index: -999;
                    background: rgb(0, 0, 0, 0.5);
                }

                .aisde_menu {
                    display: block;
                    position: fixed;
                    top: 0;
                    left: 20%;
                    right: 0;
                    z-index: -1;
                    background: $white;
                    padding: 50px;
                    border-radius: 0 0 30px 30px;
                    text-align: center;

                    box-shadow:
                    0px 10px 20px rgba(0, 0, 0, 0.2);

                    ul {
                        font-size: 24px;
                        font-weight: $fw_medium;
                        display: flex;
                        flex-direction: column;
                        gap: 60px;
                        padding: 20px;

                        li:hover {
                            color: $m_color_01;
                            text-decoration: underline;
                            text-underline-offset: 8px;
                        }
                    }
                }
            }

            .language {
                button {
                    font-size: 12px;
                }
            }
        }
    }
}