@import './variable';
@import './mixin';

#Product {
    padding: 130px 0 0 0;

    .inner {
        @include inner;
        // outline: 1px solid red;

        .section_title {
            @include section_title;
        }

        .section_desc {
            @include section_desc;
            margin: 0 auto 60px auto;
            max-width: 1000px;
            line-height: 1.45;
        }


        .content {
            position: relative;

            .slick-slider {

                .slick-list {
                    // outline: 1px solid red;

                    .slick-slide {
                        &>div {
                            margin: 0 auto;
                            width: 1000px;
                        }
                    }
                }

                .slick-dots {
                    position: absolute;
                    bottom: -150px;

                    li {
                        width: 100px;
                        height: 100px;

                        &.slick-active {
                            border: 2px solid #b8a89d;
                        }
                    }
                }
            }

            .arrows {
                @include position (absolute, 50%, auto, auto, 50%, auto);
                transform: translate(-50%, -50%);

                width: 1280px;
                color: #d7d7d7;
                font-size: 100px;
                // outline: 1px solid red;

                .prev {
                    margin: 0 1070px 0 0;
                }

                .btn.active {
                    color: #222;
                }
            }
        }
    }

    .product_itm_wrap {
        margin: 260px 0 0 0;
        padding: 0 20px;
        width: 100%;
        height: 860px;
        background: url(../../../public/assets/product_bg.jpg)no-repeat center center/cover;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product_itm {
        @include inner;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 40px;

        .product_itm_desc {
            background: $white;
            padding: 40px;
            border-radius: 30px;
            box-shadow: 10px 10px 10px 0px rgba(198, 198, 198, 0.5);

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 15px 0;

                strong {
                    font-size: 32px;
                    font-weight: $fw_bold;
                    line-height: 1.4;
                    padding: 0 40px 5px 0;
                }
            }

            span {
                display: block;
                display: flex;
                font-size: 20px;
                font-weight: $fw_light;
                line-height: 1.45;
                margin: 0 0 10px 0;

                img {
                    height: 100%;
                    padding: 5px 0 0 0;
                    margin: 0 12px 0 0;
                }
            }

            .product_itm_desc_02_03 {
                margin: 0 0 0 30px;
            }
        }
    }
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #Product {
        @include section_m;

        .inner {
            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 auto 60px auto;
                max-width: 1000px;
                line-height: 1.45;
                padding: 0 20px;
            }

            .content {
                position: relative;
                padding: 0 20px;

                .slick-slider {
                    .slick-list {

                        .slick-slide {
                            &>div {
                                margin: 0 auto;
                                width: 100%;
                            }
                        }
                    }

                    .slick-dots {
                        position: absolute;
                        bottom: -200px;

                        li {
                            width: 55px;
                            height: 55px;
                            margin: 5px;
                        }
                    }
                }

                .arrows {
                    @include position (absolute, 50%, auto, auto, 50%, auto);
                    transform: translate(-50%, -50%);

                    display: flex;
                    justify-content: space-between;

                    width: 100%;
                    color: #d7d7d7;
                    font-size: 55px;
                    // outline: 1px solid red;

                    .prev {
                        margin: 0 0 0 -5px;
                        // outline: 1px solid red;
                    }
                }
            }
        }

        .product_itm_wrap {
            margin: 250px 0 0 0;
            width: 100%;
            height: 100%;
            padding: 50px 0;
            background: url(../../../public/assets/product_bg_m.jpg)no-repeat center center/cover;
        }

        .product_itm {
            @include inner;
            display: block;

            .slick-slider {
                width: 100%;
                // background: red;

                .slick-list {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    // background: yellowgreen;

                    .slick-slide {
                        &>div {
                            margin: 0 15px;
                        }
                    }
                }

                .slick-dots {
                    position: absolute;
                    bottom: -110px;

                    li {
                        margin: 0 15px;

                        button {

                            &:before {
                                width: 40px;
                                height: 5px;
                                border-radius: 10px;
                                background: #d3d3d3;
                                font-size: 0;
                            }
                        }

                        &.slick-active button:before {
                            width: 40px;
                            height: 5px;
                            border-radius: 10px;
                            background: $m_color_01;
                        }
                    }
                }
            }

            .product_itm_desc {
                background: $white;
                padding: 20px;
                border-radius: 15px;
                box-shadow: none;
                position: relative;

                min-height: 360px;

                div {
                    margin: 0 0 20px 0;


                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 60px;
                    }

                    strong {
                        font-size: 20px;
                        max-width: 90%;
                        // outline: 1px solid red;
                    }
                }

                span {
                    font-size: 16px;
                    margin: 0 0 10px 0;

                    img {
                        height: 100%;
                        padding: 5px 0 0 0;
                        margin: 0 12px 0 0;
                    }
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #Product {
        @include section_m;

        .inner {
            .section_title {
                @include section_title_m;
            }

            .section_desc {
                @include section_desc_m;
                margin: 0 auto 60px auto;
                max-width: 1000px;
                line-height: 1.45;
                padding: 0 20px;
            }

            .content {
                position: relative;
                padding: 0 20px;

                .slick-slider {
                    .slick-list {

                        .slick-slide {
                            &>div {
                                margin: 0 auto;
                                width: 100%;
                            }
                        }
                    }

                    .slick-dots {
                        position: absolute;
                        bottom: -200px;

                        li {
                            width: 55px;
                            height: 55px;
                            margin: 5px;
                        }
                    }
                }

                .arrows {
                    @include position (absolute, 50%, auto, auto, 50%, auto);
                    transform: translate(-50%, -50%);

                    display: flex;
                    justify-content: space-between;

                    width: 100%;
                    color: #d7d7d7;
                    font-size: 55px;
                    // outline: 1px solid red;

                    .prev {
                        margin: 0 0 0 -5px;
                        // outline: 1px solid red;
                    }
                }
            }
        }

        .product_itm_wrap {
            margin: 250px 0 0 0;
            width: 100%;
            height: 100%;
            padding: 50px 0;
            background: url(../../../public/assets/product_bg_m.jpg)no-repeat center center/cover;
        }

        .product_itm {
            @include inner;
            display: block;

            .slick-slider {
                width: 100%;
                // background: red;

                .slick-list {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    // background: yellowgreen;

                    .slick-slide {
                        &>div {
                            margin: 0 15px;
                        }
                    }
                }

                .slick-dots {
                    position: absolute;
                    bottom: -110px;

                    li {
                        margin: 0 15px;

                        button {

                            &:before {
                                width: 40px;
                                height: 5px;
                                border-radius: 10px;
                                background: #d3d3d3;
                                font-size: 0;
                            }
                        }

                        &.slick-active button:before {
                            width: 40px;
                            height: 5px;
                            border-radius: 10px;
                            background: $m_color_01;
                        }
                    }
                }
            }

            .product_itm_desc {
                background: $white;
                padding: 40px;
                border-radius: 15px;
                box-shadow: none;
                position: relative;

                min-height: 370px;

                div {
                    margin: 0 0 30px 0;

                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 70px;
                    }

                    strong {
                        font-size: 22px;
                        max-width: 90%;
                        // outline: 1px solid red;
                    }
                }

                span {
                    font-size: 16px;

                    img {
                        height: 100%;
                        padding: 5px 0 0 0;
                        margin: 0 12px 0 0;
                    }
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #Product {
        padding: 80px 0 0 0;
    
        .inner {
            @include inner;
            // outline: 1px solid red;
    
            .section_title {
                @include section_title_t;
            }
    
            .section_desc {
                @include section_desc_t;
                margin: 0 auto 60px auto;
                max-width: 1000px;
                line-height: 1.45;
                padding: 0 20px;
            }
    
            .content {
                position: relative;
    
                .slick-slider {

                    .slick-list {
                        // outline: 1px solid red;
    
                        .slick-slide {
                            &>div {
                                margin: 0 auto;
                                width: 100%;

                                img {
                                    width: 80%;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
    
                    .slick-dots {
                        position: absolute;
                        bottom: -100px;
    
                        li {
                            width: 70px;
                            height: 70px;
    
                            &.slick-active {
                                border: 2px solid #b8a89d;
                            }
                        }
                    }
                }
    
                .arrows {
                    @include position (absolute, 50%, auto, auto, 50%, auto);
                    transform: translate(-50%, -50%);

                    display: flex;
                    justify-content: space-between;

                    width: 100%;
                    color: #d7d7d7;
                    font-size: 65px;
                    // outline: 1px solid red;

                    .prev {
                        margin: 0 0 0 20px;
                        // outline: 1px solid red;
                    }

                    .next {
                        margin: 0 23px 0 0;
                    }
                }
            }
        }
        .product_itm_wrap {
            margin: 200px 0 100px 0;
            width: 100%;
            height: 100%;
            padding: 50px 0;
            background: url(../../../public/assets/product_bg_m.jpg)no-repeat center center/cover;
        }

        .product_itm {
            @include inner;
            display: block;

            .slick-slider {
                width: 100%;
                // background: red;

                .slick-list {
                    width: calc(100% - 40px);
                    margin: 0 auto;
                    // background: yellowgreen;

                    .slick-slide {
                        &>div {
                            margin: 0 15px;
                        }
                    }
                }

                .slick-dots {
                    position: absolute;
                    bottom: -110px;

                    li {
                        margin: 0 15px;

                        button {

                            &:before {
                                width: 40px;
                                height: 5px;
                                border-radius: 10px;
                                background: #d3d3d3;
                                font-size: 0;
                            }
                        }

                        &.slick-active button:before {
                            width: 40px;
                            height: 5px;
                            border-radius: 10px;
                            background: $m_color_01;
                        }
                    }
                }
            }

            .product_itm_desc {
                background: $white;
                padding: 40px;
                border-radius: 15px;
                box-shadow: none;
                position: relative;

                min-height: 260px;

                div {
                    margin: 0 0 30px 0;

                    img {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 70px;
                    }

                    strong {
                        font-size: 22px;
                        max-width: 90%;
                        // outline: 1px solid red;
                    }
                }

                span {
                    font-size: 16px;

                    img {
                        height: 100%;
                        padding: 5px 0 0 0;
                        margin: 0 12px 0 0;
                    }
                }
            }
        }
        // .product_itm_wrap {
        //     margin: 170px 0 0 0;
        //     width: 100%;
        //     height: 500px;
        //     background: url(../../../public/assets/product_bg.jpg)no-repeat center center/cover;
    
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        // }
    
        // .product_itm {
        //     @include inner;
        //     display: grid;
        //     grid-template-columns: 1fr 1fr;
        //     grid-gap: 40px;
        //     padding: 0 20px;
    
        //     .product_itm_desc {
        //         background: $white;
        //         padding: 30px;
        //         border-radius: 30px;
        //         box-shadow: 10px 10px 10px 0px rgba(198, 198, 198, 0.5);
    
        //         div {
        //             display: flex;
        //             align-items: center;
        //             justify-content: space-between;
        //             margin: 0 0 20px 0;
    
        //             strong {
        //                 font-size: 22px;
        //                 font-weight: $fw_bold;
        //                 line-height: 1.4;
        //                 padding: 0 40px 5px 0;
        //             }
                    
        //             img {
        //                 width: 60px;
        //             }
        //         }
    
        //         span {
        //             display: block;
        //             display: flex;
        //             font-size: 18px;
        //             font-weight: $fw_light;
        //             line-height: 1.45;
    
        //             img {
        //                 height: 100%;
        //                 padding: 5px 0 0 0;
        //                 margin: 0 12px 0 0;
        //             }
        //         }
    
        //         .product_itm_desc_02_03 {
        //             margin: 0 0 0 30px;
        //         }
        //     }
        // }

        
    }
}

