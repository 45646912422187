@import './variable';
@import './mixin';

#footer {
    background: #f8f8f8;
    padding: 0 20px;

    .inner {
        @include inner;
        margin: 0 auto;
        padding: 50px 0 40px 0;

        display: flex;
        justify-content: space-between;

        .footer_info_wrap {
            // outline: 1px solid red;

            .footer_privacy {
                display: flex;
                font-size: 15px;
                font-weight: $fw_medium;
                color: #555;
                margin: 0 0 20px 0;

                svg {
                    font-size: 18px;
                    margin: -3px 5px 0 0;
                }

                &:hover {
                    color: $m_color_01;
                    transition: 0.3s;
                }
            }

            .footer_info {
                font-size: 15px;
                font-weight: $fw_light;
                color: #888;
                line-height: 1.45;

                .copyright {
                    text-transform: uppercase;
                }

                span {
                    display: block;
                    margin: 0 20px 10px 0;
                }

                .footer_info_top,
                .footer_info_bottom {
                    display: flex;

                    span {
                        display: block;
                        margin: 0 20px 8px 0;
                    }
                }

                .footer_info_bottom {
                    span {
                        margin: 0 20px 0 0;
                    }
                }
            }
        }

        .footer_social {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: end;

            .footer_logo {
                max-width: 200px;
            }

            .footer_sns {
                display: flex;
                gap: 30px;
                padding: 15px 0 0 0;

                a {
                    width: 28px;
                    height: 23px;
                    // outline: 1px solid red;

                    &:nth-child(6) {
                        width: 15px;
                    }

                    li {
                        list-style-type: none;
                    }
                }

                $snsIcons: (
                    sns01: 1,
                    sns02: 2,
                    sns03: 3,
                    sns04: 4,
                    sns05: 5,
                );

            @each $icon,
            $index in $snsIcons {
                a:nth-child(#{$index}) {
                    background: url(../../../public/assets/#{$icon}.png)no-repeat center center/contain;
                }

                a:nth-child(#{$index}):hover {
                    background: url(../../../public/assets/#{$icon}_hover.png)no-repeat center center/contain;
                    transition: 0.3s;
                }

                // note
                // @each 반복문은 $snsIcons 맵의 모든 항목에 대해 반복하며, 각 반복마다 $icon과 $index 변수에 해당하는 값을 할당
                // $icon 변수는 맵의 키(key)인 아이콘 이름을 나타내고, $index 변수는 맵의 값(value)인 인덱스
            }
        }
    }
}
}

// 플립 
@media screen and (min-width: 280px) and (max-width: 360px) {
    #footer {
        padding: 0;

        .inner {
            padding: 30px 20px 40px 20px;
            display: block;

            .footer_info_wrap {
                // outline: 1px solid red;

                .footer_privacy_wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 30px 0;

                    img {
                        width: 40%;
                    }

                    .footer_privacy {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: $fw_medium;
                        color: #555;
                        margin: 0 0 0 0;

                        svg {
                            font-size: 18px;
                            margin: -3px 5px 0 0;
                        }

                        &:hover {
                            color: $m_color_01;
                            transition: 0.3s;
                        }
                    }
                }

                .footer_info {
                    font-size: 12px;

                    .copyright {
                        margin: 15px 0 0 0;
                    }

                    .footer_info_top,
                    .footer_info_bottom {
                        display: flex;
                        flex-direction: column;

                        span {
                            display: block;
                            margin: 0 10px 8px 0;
                        }
                    }

                    .footer_info_bottom {
                        margin: 0 0 0 0;
                    }
                }
            }

            .footer_social {
                align-items: center;

                margin: 0 0 30px 0;

                .footer_logo {
                    max-width: 200px;
                }

                .footer_sns {
                    display: flex;
                    gap: 15px;
                    padding: 15px 0 0 0;
                }
            }
        }
    }
}

// 모바일
@media screen and (min-width: 360px) and (max-width: 767px) {
    #footer {
        padding: 0;

        .inner {
            padding: 30px 20px 40px 20px;
            display: block;

            .footer_info_wrap {
                // outline: 1px solid red;

                .footer_privacy_wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin: 0 0 20px 0;

                    img {
                        width: 40%;
                    }

                    .footer_privacy {
                        display: flex;
                        align-items: center;
                        font-size: 15px;
                        font-weight: $fw_medium;
                        color: #555;
                        margin: 0 0 0 0;

                        svg {
                            font-size: 18px;
                            margin: -3px 5px 0 0;
                        }

                        &:hover {
                            color: $m_color_01;
                            transition: 0.3s;
                        }
                    }
                }

                .footer_info {
                    font-size: 15px;
                    font-weight: $fw_light;
                    color: #888;
                    line-height: 1.45;

                    .copyright {
                        text-transform: uppercase;
                    }

                    span {
                        display: block;
                        margin: 0 30px 10px 0;
                    }

                    .footer_info_top {
                        display: flex;
                        flex-direction: column;
                        margin: 0 0 10px 0;

                        span {
                            display: block;
                            margin: 0 0 8px 0;

                            &:last-child {
                                margin: 0 30px 8px 0;
                            }
                        }
                    }
                }
            }

            .footer_social {
                align-items: center;

                margin: 0 0 30px 0;

                .footer_logo {
                    max-width: 200px;
                }
            }
        }
    }
}

// 테블릿
@media screen and (min-width: 768px) and (max-width: 1138px) {
    #footer {
        .inner {
            width: 100%;
            padding: 50px 80px 40px 20px;

            .footer_info_wrap {
                // outline: 1px solid red;

                .footer_privacy {
                    display: flex;
                    font-size: 15px;
                    font-weight: $fw_medium;
                    color: #555;
                    margin: 0 0 20px 0;

                    svg {
                        font-size: 18px;
                        margin: -3px 5px 0 0;
                    }

                    &:hover {
                        color: $m_color_01;
                        transition: 0.3s;
                    }
                }

                .footer_info {
                    font-size: 13px;
                    font-weight: $fw_light;
                    color: #888;
                    line-height: 1.45;

                    .copyright {
                        text-transform: uppercase;
                    }

                    span {
                        display: block;
                        margin: 0 20px 10px 0;
                    }

                    .footer_info_top,
                    .footer_info_bottom {
                        display: flex;

                        span {
                            display: block;
                            margin: 0 20px 8px 0;
                        }
                    }

                    .footer_info_bottom {
                        span {
                            margin: 0 20px 0 0;
                        }
                    }
                }
            }

            .footer_social {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: end;

                .footer_logo {
                    max-width: 160px;
                }

                .footer_sns {
                    display: flex;
                    gap: 15px;
                    padding: 15px 0 0 0;

                    a {
                        width: 25px;
                        height: 23px;
                        // outline: 1px solid red;

                        &:nth-child(6) {
                            width: 15px;
                        }
                    }

                    $snsIcons: (
                        sns01: 1,
                        sns02: 2,
                        sns03: 3,
                        sns04: 4,
                        sns05: 5,
                        sns06: 6
                    );

                @each $icon,
                $index in $snsIcons {
                    a:nth-child(#{$index}) {
                        background: url(../../../public/assets/#{$icon}.png)no-repeat center center/contain;
                    }

                    a:nth-child(#{$index}):hover {
                        background: url(../../../public/assets/#{$icon}_hover.png)no-repeat center center/contain;
                        transition: 0.3s;
                    }

                    // note
                    // @each 반복문은 $snsIcons 맵의 모든 항목에 대해 반복하며, 각 반복마다 $icon과 $index 변수에 해당하는 값을 할당
                    // $icon 변수는 맵의 키(key)인 아이콘 이름을 나타내고, $index 변수는 맵의 값(value)인 인덱스
                }
            }
        }
    }

}
}